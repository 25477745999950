import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { AuthProvider, useAuthentication } from '../hooks/useAuthentication';
import { cancelAllRequest, setUrl } from '../helpers/browser';
import useQueryParams from '../hooks/useQueryParams';
import useSEO from '../hooks/useSEO';
import theme from '../themes/default';
import Script from 'next/script';

const Application = ({ Component, pageProps }) => {
  const params = useQueryParams();
  const seo = useSEO(pageProps);

  React.useEffect(() => {
    setUrl('override_referrer', null);
    setUrl('stripe_success', null);

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <AuthProvider stripeSuccess={params.stripe_success}>
      <Head>
        <meta charSet="utf-8" key="charSet" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="WOXO" />
        <link rel="shortcut icon" href="/static/favicon.ico" />
        <title>{seo.title}</title>
        <link rel="canonical" href={seo.canonical} />
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <meta property="og:title" content={seo['og:title']} />
        <meta property="og:url" content={seo['og:url']} />
        <meta property="og:description" content={seo['og:description']} />
        <meta property="og:image" content={seo['og:image']} />
        <meta property="og:image:width" content={seo['og:image:width']} />
        <meta property="og:image:height" content={seo['og:image:height']} />
        <meta name="twitter:card" content={seo['twitter:card']} />
        <meta name="twitter:title" content={seo['twitter:title']} />
        <meta property="twitter:url" content={seo['twitter:url']} />
        <meta name="twitter:description" content={seo['twitter:description']} />
        <meta name="twitter:image" content={seo && seo['twitter:image']} />
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Head>
      <Script id="google-analytics" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NMP7265');`}
      </Script>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          <Component {...pageProps} />
        </ConfirmProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

Application.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any)
};

Application.defaultProps = {
  pageProps: {}
};

Router.onRouteChangeStart = url => {
  cancelAllRequest();
};

export default Application;
