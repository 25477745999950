module.exports = [
  { name: "Linear", value: "linear" },
  { name: "Radial", value: "radial" },
  { name: "Undulations", value: "undulations" },
  { name: "Bows", value: "bows" },
  { name: "Peaks", value: "peaks" },
  { name: "Waves", value: "waves" },
  { name: "Squares", value: "squares" },
  { name: "Clouds", value: "clouds" },
  { name: "Color", value: "color" }
];
