const useSEO = ({ seo }) => {
  return {
    title:
      (seo && seo.title) ||
      'WOXO | Make Videos & Social Content. Faster',
    canonical: (seo && seo.canonical) || 'https://woxo.tech',
    description:
      (seo && seo.description) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    keywords:
      (seo && seo.keywords) ||
      'widget, embed, instagram, tiktok, facebook, youtube, twitter, Social Media, tiktok videos, social feed, woxo, optimization',
    'og:title':
      (seo && seo['og:title']) ||
      'WOXO | Make Videos & Social Content. Faster',
    'og:url': (seo && seo['og:url']) || 'https://woxo.tech',
    'og:description':
      (seo && seo['og:description']) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    'og:image':
      (seo && seo['og:image']) ||
      'https://res.cloudinary.com/dakp804eh/image/upload/v1622122103/woxo-landing-page/Header2.png',
    'og:image:width': (seo && seo['og:image:width']) || '1200',
    'og:image:height': (seo && seo['og:image:height']) || '630',
    'twitter:card': (seo && seo['twitter:card']) || 'summary_large_image',
    'twitter:title':
      (seo && seo['twitter:title']) ||
      'WOXO | Make Videos & Social Content. Faster',
    'twitter:url': (seo && seo['twitter:url']) || 'https://woxo.tech',
    'twitter:description':
      (seo && seo['twitter:description']) ||
      'Are you spending too much time creating video and social content Don’t go anywhere, WOXO is about to give you back time!',
    'twitter:image':
      (seo && seo['twitter:image']) ||
      'https://res.cloudinary.com/dakp804eh/image/upload/v1622122103/woxo-landing-page/Header2.png'
  };
};

export default useSEO;
