import TagManager from 'react-gtm-module';

export const gtmFireEvent = ({
  event = 'onButtonClick',
  category = 'general',
  action,
  label,
  gclid
}) => {
  let id = '';
  try {
    id = gclid || window.getCookie('gclid') || '';
  } catch (error) {}

  const tagManagerArgs = {
    gtmId: 'GTM-NMP7265',
    dataLayer: { event, category, action, label, gclid: id }
  };
  try {
    TagManager.initialize(tagManagerArgs);
  } catch (error) {}
};
